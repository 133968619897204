/* .signature-canvas{
    border: 1px solid black;
} */
.signature-pad-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
  }
  
  .signature-canvas {
    width: 100%;
    height: auto;
    max-width: 730px;
    max-height: 200px;
    border: 1px solid black;
  }
  
  .signature-pad-buttons {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  
  @media (min-width: 600px) {
    .signature-pad-buttons {
      flex-direction: row;
    }
  }
  