.statcontainer {
    align-self: center;
    padding: 16px;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    margin: 8px;
    box-shadow: 0 2px 3.84px 0 rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 200px;
  }
  
  .stattop {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .statvalue {
    font-size: 24px;
    font-weight: bold;
    color: #235789;
  }
  
  .stattitle {
    font-size: 16px;
    margin-left: 8px;
    font-weight: bold;
  }
  
  .statsubtitle {
    font-size: 20px;
    font-weight: bold;
  }
  