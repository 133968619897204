.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.shift-detail-body {
  /* display: flex;
  flex-direction: column; */
  /* width: 566px; */
  /* min-width: 600px; */
  /* min-height: 200px; */
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: white;
  /* margin-left: 25rem; */
  /* margin-bottom: 25px;
  margin-top: 20px;
  padding-bottom: 1rem; */
}
.paddingVertical {
  padding-top: 16px;
  padding-bottom: 16px;
}
.paddingHorizontal {
  padding-left: 16px;
  padding-right: 16px;
}

.section-header-text {
  font-size: x-large;
  font-weight: bold;
}

.flex-container {
  padding: 0;
  margin: 0;
  list-style: none;

  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
}

.flex1 {
  flex: 0 0 20em;
}
.flex2 {
  flex: 2 2 20em;
}

.app-container {
  display: "flex";
  flex-direction: "column";
  justify-content: "center";
  align-items: "center";
  min-width: 800;
  padding: 20;
  background-color: grey;
}

.min-width {
  display: "flex";
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
  /* background-color: hotpink;
 
  width: "100vw"; */
}
